import { css } from 'linaria'
import * as colors from '../../styles/colors'
import { rel } from '../../styles/dimensions'

export const dropdownButtonWrapper = css`
  position: relative;
  display: flex;
  justify-content: stretch;
`

export const dropdownButton = css`
  display: flex;
  align-items: center;
  padding: ${rel(8)}rem ${rel(16)}rem;
  font-weight: bold;
  color: ${colors.primaryColor[2]};
  background-color: ${colors.white};
  border: none;
  border-radius: ${rel(4)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(8)}rem ${colors.boxShadow};
  cursor: pointer;

  svg {
    margin-left: ${rel(8)}rem;
  }
`

export const dropdownButtonContent = css`
  position: absolute;
  top: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  margin-top: ${rel(8)}rem;
  padding: ${rel(7)}rem 0;
  background: #fff;
  border-radius: 0 0 ${rel(8)}rem ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${colors.boxShadow};
  animation: fadeIn 0.35s;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-top: 0;

    > a {
      display: block;
      padding: ${rel(4)}rem ${rel(16)}rem;
      font-size: ${rel(16)}rem;
      line-height: 1.5;
      transition: background-color 0.2s ease;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background-color: ${colors.backgroundColor[2]};
      }
    }
  }

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    15% {
      display: flex;
    }

    30% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
`
