import { Enum } from 'typescript-string-enums'
import { useEffect, useRef, useState } from 'react'
import { objectOmit } from '@cvut/profit-utils'
import urlcat, { ParamMap } from 'urlcat'
import {
  Thesis, ThesisAction, ActionMessage, ThesesEventLog, ThesesMeta, ThesisNew, ThesisPatch, ThesisRef,
} from '@cvut/profit-api-types/lib/theses'

import { apiPrefix } from '../../config'
import { createRequestor } from '../makeRequest'
import requestErrorFormatter from '../requestErrorFormatter'
import { useRequest, UseRequestStatus, UseRequestConfig } from '../useRequest'
import { usePaginatedRequest, UsePaginatedRequestStatus } from '../usePaginatedRequest'
import { useLocale } from '../../locale'


// TODO: Remove SUPERSEDE from this enum and implement action panel for it.
export const ThesisPanelAction = objectOmit(ThesisAction,
  'CREATE', 'READ', 'READ_FILES', 'LIST', 'UPDATE', 'DELETE', 'ARCHIVE', 'ASSIGN_DIRECTLY', 'REQUEST_APPROVAL',
  'UNSUBMIT_REVIEWER_REPORT',
  'UNSUBMIT_SUPERVISOR_REPORT')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ThesisPanelAction = Enum<typeof ThesisPanelAction>

const thesesApiPrefix = `${apiPrefix}/theses`
const thesesRequestor = createRequestor(thesesApiPrefix)
const myThesesApiUrl = `${apiPrefix}/me/theses`
const defaultPageSize = 30 // FIXME - 30 might be too few on larger displays

function useThesesRequest<T> (config: UseRequestConfig, baseUrl = thesesApiPrefix) {
  return useRequest<T>(createRequestor(baseUrl), config)
}

export function useThesis (): [
  UseRequestStatus<Thesis>,
  (thesisId: Thesis['id']) => Promise<void>,
] {
  const [requestStatus, sendRequest] = useThesesRequest<Thesis>({
    defaultErrorMessage: requestErrorFormatter,
  })

  return [
    requestStatus,
    async (thesisId) => await sendRequest('GET', '/:thesisId', { thesisId }),
  ]
}

export function usePaginatedTheses (params: ParamMap, myThesesOnly?: boolean, pageSize?: number): [
  UsePaginatedRequestStatus<Thesis>,
  boolean,
  () => void,
] {
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  return usePaginatedRequest<Thesis>(
    {
      defaultErrorMessage: () => l.errorMessages.api.defaultGetList(thesisInLocale),
    },
    pageSize ?? defaultPageSize,
    myThesesOnly ? myThesesApiUrl : thesesApiPrefix,
    params,
  )
}

export function useCreateThesis (): [
  UseRequestStatus<ThesisRef>,
  (thesisProposal: ThesisNew) => Promise<void>,
] {
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useThesesRequest<ThesisRef>({
    defaultErrorMessage: () => l.errorMessages.api.defaultCreate(thesisInLocale),
  })

  return [requestStatus, async (thesisProposal) => await sendRequest('POST', '', {}, {
    body: JSON.stringify(thesisProposal),
  })]
}

export async function updateThesis (
  thesisId: Thesis['id'],
  data: ThesisPatch,
  requestor = thesesRequestor,
): Promise<unknown> {
  return await requestor('PATCH', ':thesisId', { thesisId }, {
    body: JSON.stringify(data),
  })
}

export function useUpdateThesis (): [
  UseRequestStatus<void>,
  (thesisId: Thesis['id'], thesisPatch: ThesisPatch) => Promise<void>,
] {
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useThesesRequest<void>({
    defaultErrorMessage: err => l.errorMessages.api.defaultPatch(thesisInLocale, err.url.params?.thesisId ?? ''),
  })

  return [requestStatus, async (thesisId, thesisPatch) => await sendRequest('PATCH', ':thesisId', { thesisId }, {
    body: JSON.stringify(thesisPatch),
  })]
}

export function useDeleteThesis (): [
  UseRequestStatus<void>,
  (thesisId: Thesis['id']) => Promise<void>
] {
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useThesesRequest<void>({
    defaultErrorMessage: err => l.errorMessages.api.defaultDelete(thesisInLocale, err.url.params?.thesisId ?? ''),
  })

  return [requestStatus, async (thesisId) => await sendRequest('POST', ':thesisId/actions/delete', { thesisId })]
}

export function useThesisActions (thesisId: number, errorHandlers: UseRequestConfig['errorHandlers'] = {}): [
  UseRequestStatus<void>,
  (action: ThesisPanelAction | 'ARCHIVE', bodyData?: ActionMessage) => void,
] {
  const { l } = useLocale()
  const requestor = createRequestor(`${apiPrefix}/theses/${thesisId}/actions/`)

  const [requestStatus, sendRequest] = useRequest<void>(requestor, {
    defaultErrorMessage: () => l.errorMessages.api.defaultThesisActionsErrorMessage,
    errorHandlers,
  })

  return [requestStatus, async (action, bodyData) => {
    const config: RequestInit = {
      body: JSON.stringify(bodyData),
    }
    await sendRequest('POST', action, {}, config)
  }]
}

export function useThesesEventLog (params: { thesisId: number }, pageSize = 10): [
  UsePaginatedRequestStatus<ThesesEventLog>,
  boolean,
  () => void,
] {
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()

  return usePaginatedRequest<ThesesEventLog>(
    {
      defaultErrorMessage: err => l.eventLog.errorGettingEventLog(thesisInLocale, err.url.params?.thesisId ?? ''),
    },
    pageSize,
    ':thesisId/eventlog',
    params,
    thesesRequestor,
  )
}

export function useThesesMeta (): [
  UseRequestStatus<ThesesMeta>,
  () => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useThesesRequest<ThesesMeta>({
    defaultErrorMessage: () => l.errorGettingThesesMetadata,
  })

  return [
    requestStatus,
    async () => await sendRequest('GET', '/meta'),
  ]
}

export function getThesesExportCsvUrl (params: ParamMap, myThesesOnly?: boolean): string {
  return urlcat(`${myThesesOnly ? myThesesApiUrl : thesesApiPrefix}.csv`, params)
}

export function createThesisAssignmentPrintoutPDFUrl (thesisId: Thesis['id']): string {
  return urlcat(`${thesesApiPrefix}/:thesisId/assignment-printout.pdf`, { thesisId })
}

export function useThesisDownload (): [
  UseRequestStatus<Blob>,
  (url: string) => Promise<void>
] {
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  const [thesisDownloadStatus, sendRequest] = useThesesRequest<Blob>({
    defaultErrorMessage: err => l.errorMessages.api.defaultDownload(thesisInLocale, err.url.params?.thesisId ?? ''),
  }, '')

  return [
    thesisDownloadStatus,
    async (url) => await sendRequest('GET', url),
  ]
}

export function useThesisFinalTextUpload (thesisId: Thesis['id']): [
  UseRequestStatus<void>,
  (data: Blob) => Promise<void>
] {
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  const [thesisUploadStatus, sendRequest] = useThesesRequest<void>({
    defaultErrorMessage: err => l.errorMessages.api.defaultUpload(thesisInLocale, err.url.params?.thesisId ?? ''),
  })

  return [
    thesisUploadStatus,
    async (data) => await sendRequest(
      'PUT', '/:thesisId/files/final-text', { thesisId },
      {
        // TODO: add data transfer progress information
        body: data,
        headers: {
          'content-type': 'application/pdf',
          'content-length': String(data.size),
        },
      }),
  ]
}

// TODO move somewhere to some other constants?
let uploadChunkSize = 100_000
const maxRequestDuration = 30

type UploadState = 'pending' | 'running' | 'succeeded' | 'failed'

export interface UploadQueueItem {
  readonly file: File
  readonly currentSize: number
  readonly totalSize: number
  state?: UploadState
}

export interface UploadQueue {
  addUploadItem: (item: UploadQueueItem) => void
  getQueuedFile: (filename: string) => File | undefined
  removeUploadItem: (filename: string) => void
  startUpload: () => Promise<void>
  queue: UploadQueueItem[]
  queueState: UploadState
}

// TODO - refactor into smaller pieces
export const useUploadQueue = (thesisId: Thesis['id']): UploadQueue => {
  const xhr = useRef<XMLHttpRequest | null>(null)
  const [queue, setQueue] = useState<UploadQueueItem[]>([])
  const [queueState, setQueueState] = useState<UploadState>('pending')
  const isOKToContinue = useRef(true)
  const resolveCallback = useRef<CallableFunction>()
  const rejectCallback = useRef<CallableFunction>()

  useEffect(() => {
    if (queueState === 'succeeded') {
      resolveCallback.current?.()
    }
    if (queueState === 'failed') {
      rejectCallback.current?.()
    }
  }, [queueState])

  function doProgressUpdate (filename: string, offset: number, e: ProgressEvent) {
    const currentSize = offset + e.loaded

    setQueue(queue => (
      queue.map(item => (
        item.file.name === filename
          ? { ...item, currentSize, state: 'running' as UploadState }
          : item
      ))
    ))
  }

  async function doUpload () {
    // TODO - handle upload errors, stop current upload and don't go to the next one
    // TODO - check queue is always the current queue
    for (const item of queue) {
      // console.log('processing item', item)
      if (!isOKToContinue.current) {
        break
      }

      const totalSize = item.file.size
      const filename = item.file.name
      const url = urlcat(`${thesesApiPrefix}/:thesisId/attachments/:filename`, { thesisId, filename })

      let currentOffset = item.currentSize

      while (currentOffset < item.file.size && isOKToContinue.current) {
        const uploadChunk = item.file.slice(currentOffset, currentOffset + uploadChunkSize)
        const rangeStart = currentOffset
        const rangeEnd = rangeStart + uploadChunk.size - 1

        const t1 = new Date().getTime()

        await new Promise<number | null>((resolve, reject) => {
          xhr.current = new XMLHttpRequest()

          const uploadProgress = doProgressUpdate.bind(xhr.current, item.file.name, rangeStart)

          const handleUploadDone = function (offset: number, e: ProgressEvent) {
            const finalSize = offset + e.loaded

            setQueue(queue => (
              queue.map(item => (
                item.file.name === filename
                  ? {
                    ...item,
                    currentSize: finalSize,
                    state: (e.loaded === e.total ? 'succeeded' : 'failed') as UploadState,
                  }
                  : item
              ))
            ))
            resolve(finalSize)
          }.bind(xhr.current, item.currentSize)

          const handleUploadFailure = function () {
            isOKToContinue.current = false
            setQueue(queue => (
              queue.map(item => (
                item.file.name === filename
                  ? { ...item, state: 'failed' }
                  : item
              ))
            ))
            reject(new Error('Failed upload'))
          }

          xhr.current.open('PUT', url)
          xhr.current.setRequestHeader('Content-Range', `bytes ${rangeStart}-${rangeEnd}/${totalSize}`)
          xhr.current.setRequestHeader('Content-Type', 'application/zip')
          xhr.current.addEventListener('load', handleUploadDone)
          xhr.current.addEventListener('error', handleUploadFailure)
          xhr.current.addEventListener('abort', handleUploadFailure)
          xhr.current.addEventListener('timeout', handleUploadFailure)
          xhr.current.upload.addEventListener('progress', uploadProgress)
          // FIXME - throw specific errors
          // TODO more details - filename, maybe final size...
          xhr.current.upload.addEventListener('abort', () => reject(new Error('Aborted')))
          xhr.current.upload.addEventListener('error', () => reject(new Error('Upload error')))

          xhr.current.send(uploadChunk)

          setQueue(queue => (
            queue.map(item => (
              item.file.name === filename
                ? { ...item, currentSize: rangeStart, state: 'running' as UploadState }
                : item
            ))
          ))
        })

        currentOffset += uploadChunkSize

        const t2 = new Date().getTime()

        const dt = (t2 - t1) / 1000
        const speed = uploadChunk.size / (dt > 0 ? dt : 1)

        // xhr's timeout attribute doesn't work, Chrome timeouts after max 5 minutes
        // who knows about other browsers... maxRequestDuration = 30s should be short
        // enough to avoid timing out and sending just half of the data that should
        // be sent safely should make it even safer...
        // tl;dr: adjust chunk size to available measured network speed
        uploadChunkSize = Math.min(100_000_000, Math.ceil(maxRequestDuration * speed * 0.5))
      }
    }

    setQueueState(queue.some(uploadItem => uploadItem.state === 'failed') ? 'failed' : 'succeeded')
  }

  useEffect(() => {
    if (queueState === 'running') {
      void doUpload()
    }
  }, [queueState])

  return {
    queue,
    queueState,

    async startUpload (): Promise<void> {
      return await new Promise((resolve, reject) => {
        resolveCallback.current = resolve
        rejectCallback.current = reject

        setQueueState('running')
      })
    },

    addUploadItem (newItem: UploadQueueItem) {
      setQueue(queue => [
        ...queue.filter(item => (
          item.file.name !== newItem.file.name
        )),
        {
          ...newItem,
          state: 'pending',
        },
      ])
      setQueueState('pending')
    },

    getQueuedFile (filename: string) {
      return queue.find(item => (
        item.file.name === filename
      ))?.file
    },

    removeUploadItem (filename: string) {
      setQueue(queue => (
        queue.filter(item => (
          item.file.name !== filename
        ))
      ))
    },
  }
}
