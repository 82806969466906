import React, { useState } from 'react'
import { dropdownButton, dropdownButtonWrapper, dropdownButtonContent } from './ThesisActionsDropdown.style'
import { ReactComponent as ArrowDownIcon } from '../../images/icons/ArrowDown.svg'
import { ReactComponent as ArrowUpIcon } from '../../images/icons/ArrowUp.svg'
import { useLocale } from '../../locale'

interface ThesisActionsDropdownProps {
  children: React.ReactNode
}

const ThesisActionsDropdown = ({ children }: ThesisActionsDropdownProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const { l } = useLocale()

  const toggleDropdown = (): void => {
    setOpen(!open)
  }

  return (
    <div className={dropdownButtonWrapper}>
      <button
        className={dropdownButton}
        onClick={toggleDropdown}
      >
        {l.thesis.advancedActionsDropdownTitle}
        {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>

      {open && (
        <div className={dropdownButtonContent}>
          <ul>
            {children}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ThesisActionsDropdown
